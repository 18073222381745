import React from "react";
import styles from "./index.module.scss";

interface TextInputProps {
    label?: string;
    type: string;
    id: string;
    className?: string;
    placeholder?: string;
    value: string | number | string[] | undefined;
    error?: string;
    help?: string;
    horizontal?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    maxLength?: number;
    leftNode?: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    focusOnMount?: boolean;
}

export const TextInput: React.FC<TextInputProps> = ({
    label,
    type,
    id,
    className,
    placeholder,
    value,
    error,
    help,
    horizontal,
    readonly,
    disabled = false,
    maxLength,
    leftNode = <></>,
    onChange,
    focusOnMount = false
}: TextInputProps) => {
    return (
        <>
            <div className={`${styles["input-container"]} ${className}`}>
                <label htmlFor={id}>{label}</label>
                <div className={`${horizontal ? "col-xl-10" : ""}`}>
                    <span className={styles["input-wrapper"]}>
                        {leftNode}
                        <input
                            type={type}
                            id={id}
                            placeholder={placeholder}
                            value={value || ""}
                            onChange={onChange}
                            disabled={disabled}
                            readOnly={readonly}
                            maxLength={maxLength}
                            autoFocus={focusOnMount}
                        />
                    </span>
                    <div className={error ? styles["toast"] : ""}>
                        <small
                            id={`${id}-help`}
                            aria-describedby={id}
                            className={error ? styles["error-text"] : ""}
                        >
                            {error ?? help}
                        </small>
                    </div>
                </div>
            </div>
        </>
    );
};
