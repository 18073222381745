import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { CatalogState } from "./types";
import {
    getCatalog,
    getCollection,
    getCollections,
    getCollectionVideos,
    getNextEvents
} from "./thunks";
import {
    CatalogDetailsResponse,
    CatalogResponse,
    CollectionDetailsResponse,
    CollectionResponse,
    CollectionsPagedResponse,
    CollectionVideoResponse,
    CollectionVideosPagedResponse
} from "@switcherstudio/player-api-client";
import { lesser } from "helpers/numbers";

export const DEFAULT_COLLECTIONS_PAGE_SIZE = 2;
export const DEFAULT_COLLECTION_VIDEO_PAGE_SIZE = 11;
const initialPrimaryColor = "#000000";
const initialSecondaryColor = "#FFFFFF";

const customizedKeys = [
    "buttonTextColor",
    "buttonBackgroundsLinksColor",
    "primaryColor",
    "secondaryColor",
    "donateButtonColor",
    "donateButtonTextColor",
    "upcomingBackgroundColor",
    "logoBackgroundColor",
    "playerControlsColor",
    "defaultInteractiveTab",
    "embedBackgroundColor",
    "embedTextColor",
    "interactivePanelBackgroundColor",
    "interactivePanelTextColor",
    "upcomingTextColor",
    "autoOpenInteractiveMode"
];

const omitCustomized = (obj: any) => {
    Object.keys(obj)
        .filter((k) => customizedKeys.some((key) => key === k))
        .forEach((k) => delete obj[k]);
    return obj;
};

const onlyCustomized = (obj: any) => {
    Object.keys(obj)
        .filter((k) => !customizedKeys.some((key) => key === k))
        .forEach((k) => delete obj[k]);
    return obj;
};

const omitNullorUndefined = (obj: any) => {
    Object.keys(obj)
        .filter((k) => obj[k] === null || obj[k] === undefined)
        .forEach((k) => delete obj[k]);
    return obj;
};

const initialState = {
    configuredCatalogId: null,
    configuredCollectionId: null,
    configuredBroadcastId: null,
    catalog: {
        creatorIsValid: true,
        details: {
            buttonBackgroundsLinksColor: initialPrimaryColor,
            buttonTextColor: initialSecondaryColor,
            upcomingBackgroundColor: initialPrimaryColor,
            playerControlsColor: initialSecondaryColor,
            defaultInteractiveTab: "About",
            aspectRatio: undefined,
            embedBackgroundColor: initialSecondaryColor,
            embedTextColor: initialPrimaryColor,
            interactivePanelBackgroundColor: initialSecondaryColor,
            interactivePanelTextColor: initialPrimaryColor,
            upcomingTextColor: initialSecondaryColor,
            autoOpenInteractiveMode: false,
            embeddedDisplay: "DefaultThumbnail"
        } as unknown as CatalogDetailsResponse
    },
    collections: {
        totalPages: 0,
        totalRecords: 0,
        page: 0,
        pageSize: DEFAULT_COLLECTION_VIDEO_PAGE_SIZE,
        collections: [
            {
                creatorIsValid: true,
                details: {
                    primaryColor: initialPrimaryColor,
                    secondaryColor: initialSecondaryColor,
                    donateButtonColor: initialPrimaryColor,
                    donateButtonTextColor: initialSecondaryColor,
                    logoBackgroundColor: initialPrimaryColor,
                    playerControlsColor: initialSecondaryColor,
                    defaultInteractiveTab: "About",
                    aspectRatio: undefined,
                    embedBackgroundColor: initialSecondaryColor,
                    embedTextColor: initialPrimaryColor,
                    interactivePanelBackgroundColor: initialSecondaryColor,
                    interactivePanelTextColor: initialPrimaryColor,
                    upcomingTextColor: initialSecondaryColor,
                    autoOpenInteractiveMode: false,
                    embeddedDisplay: null
                } as unknown as CollectionDetailsResponse
            }
        ]
    } as CollectionsPagedResponse,
    collectionVideosMap: {},
    isCustomized: false,
    hasLoadedCatalog: false,
    hasLoadedCollections: false,
    hasLoadedCollectionVideos: false,
    isGatedContentDisabled: true,
    loadedCollectionPageCount: 1
} as CatalogState;

export const catalogState = createSlice({
    name: "Catalog",
    initialState: initialState,
    reducers: {
        clearCatalog: (state) => {
            if (state.isCustomized) {
                return {
                    ...initialState,
                    isCustomized: state.isCustomized,
                    configuredCatalogId: state.configuredCatalogId,
                    catalog: {
                        ...state.catalog,
                        details: onlyCustomized({
                            ...state.catalog.details
                        })
                    },
                    collections: {
                        ...initialState.collections,
                        collections: state.collections.collections?.map(
                            (collection) => ({
                                ...collection,
                                details: onlyCustomized({
                                    ...collection?.details
                                }) as CollectionDetailsResponse
                            })
                        )
                    } as CollectionsPagedResponse,
                    isGatedContentDisabled: state.isGatedContentDisabled,
                    loadedCollectionPageCount: state.loadedCollectionPageCount
                };
            } else {
                return {
                    ...initialState,
                    isGatedContentDisabled: state.isGatedContentDisabled,
                    loadedCollectionPageCount: state.loadedCollectionPageCount
                };
            }
        },
        setConfiguredCatalogId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredCatalogId = payload;
        },
        setConfiguredCollectionId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredCollectionId = payload;
        },
        setConfiguredBroadcastId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.configuredBroadcastId = payload;
        },
        setCatalogDetails: (
            state,
            { payload }: PayloadAction<CatalogDetailsResponse>
        ) => {
            state.catalog.details = {
                ...state.catalog.details,
                ...omitNullorUndefined(payload)
            };
            state.isCustomized = true;
        },
        setCollectionDetails: (
            state,
            { payload }: PayloadAction<CollectionDetailsResponse>
        ) => {
            const targetCollection =
                state.collections.collections?.find(
                    (collection) => collection?.details?.id === payload.id
                ) ??
                ({
                    creatorIsValid: true
                } as CollectionResponse);

            targetCollection.details = {
                ...targetCollection.details,
                ...omitNullorUndefined(payload)
            } as CollectionDetailsResponse;

            state.collections.collections = [
                ...(state.collections.collections?.filter(
                    (collection) =>
                        collection?.details?.id !== payload.id &&
                        !!collection?.details?.id
                ) ?? []),
                targetCollection
            ];
            state.isCustomized = true;
        },
        setIsGatedContentDisabled: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isGatedContentDisabled = payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(getCatalog.fulfilled, (state, action) => {
            //console.log("getCatalog.fulfilled", action.payload);

            let details = action.payload.details;
            if (state.isCustomized) {
                details = omitCustomized(details);
            }

            state.catalog = {
                ...action.payload,
                details: {
                    ...state.catalog.details,
                    // only override detail defaults if they have value
                    ...omitNullorUndefined(details)
                }
            } as CatalogResponse;
            state.hasLoadedCatalog = true;
        });
        builder.addCase(getCollections.fulfilled, (state, action) => {
            if (!action.payload) return;

            state.collections.totalPages = action.payload.totalPages;
            state.collections.totalRecords = action.payload.totalRecords;
            state.collections.page = action.payload.page;
            state.collections.pageSize = action.payload.pageSize;

            state.loadedCollectionPageCount =
                (action.payload.page ?? 0) > state.loadedCollectionPageCount
                    ? action.payload.page ?? 0
                    : state.loadedCollectionPageCount;

            state.collections.collections = [
                ...(state.collections.collections || []),
                ...(action.payload.collections || [])
            ];

            state.hasLoadedCollections = true;

            action.payload.collections?.forEach((collection) => {
                // inject the featured trailer
                if (
                    collection.details &&
                    collection.details.idleState === "SelectVideo"
                ) {
                    const key = collection.details.id!;
                    state.collectionVideosMap[key] ||=
                        {} as CollectionVideosPagedResponse;
                    if (collection.featuredTrailer) {
                        state.collectionVideosMap[key].items = [
                            {
                                broadcast: collection.featuredTrailer,
                                details: {
                                    id: collection.featuredTrailer.details?.id
                                }
                            } as CollectionVideoResponse,
                            ...(state.collectionVideosMap[key].items || [])
                        ];
                    }
                }
            });
        });

        // Single collection used for player
        builder.addCase(getCollection.fulfilled, (state, action) => {
            let details = action.payload.details;
            const { shouldInjectTrailer } = action.meta.arg;

            if (state.isCustomized) {
                details = omitCustomized(details);
            }

            state.collections.collections = [
                {
                    ...state.collections.collections?.[0],
                    ...action.payload,
                    details: {
                        ...state.collections.collections?.[0].details,
                        // only override detail defaults if they have value
                        ...omitNullorUndefined(details)
                    }
                } as CollectionResponse
            ];

            // inject the featured trailer
            if (
                details &&
                details.idleState === "SelectVideo" &&
                action.payload?.details?.embeddedDisplay !==
                    "DefaultThumbnail" &&
                shouldInjectTrailer
            ) {
                const key = details.id!;
                state.collectionVideosMap[key] ||=
                    {} as CollectionVideosPagedResponse;
                if (action.payload.featuredTrailer) {
                    state.collectionVideosMap[key].items = [
                        {
                            broadcast: action.payload.featuredTrailer,
                            details: {
                                id: action.payload.featuredTrailer.details?.id
                            }
                        } as CollectionVideoResponse,
                        ...(state.collectionVideosMap[key].items || [])
                    ];
                }
            }

            state.hasLoadedCollections = true;
        });
        builder.addCase(getCollectionVideos.pending, (state, action) => {
            const key = action.meta.arg.collectionId!;

            state.collectionVideosMap[key] ||=
                {} as CollectionVideosPagedResponse;

            let nextPageSize = DEFAULT_COLLECTION_VIDEO_PAGE_SIZE;
            const { totalRecords, items } = state.collectionVideosMap[key];

            if (totalRecords && items) {
                nextPageSize = lesser(
                    totalRecords - items.length,
                    DEFAULT_COLLECTION_VIDEO_PAGE_SIZE
                );
            }
            state.collectionVideosMap[key].items = [
                ...(state.collectionVideosMap[key].items || []),
                ...(new Array<CollectionVideoResponse>(nextPageSize) || [])
            ];
        });
        builder.addCase(getCollectionVideos.fulfilled, (state, action) => {
            const key = action.payload.collectionId!;
            state.collectionVideosMap[key] ||=
                {} as CollectionVideosPagedResponse;

            const collection = state.collections?.collections?.find(
                (collection) => collection?.details?.id === key
            );

            // Filter out the featured video and gated items (when in compat mode) from the collection items
            action.payload.items = action.payload.items?.filter((item) => {
                const isFeaturedVideo =
                    collection?.details?.idleState === "SelectVideo" &&
                    item?.details?.broadcastId ===
                        collection?.details?.idleBroadcastId;
                return (
                    !isFeaturedVideo &&
                    (state.isGatedContentDisabled ? !item?.isGated : true)
                );
            });

            // Filter out live videos from the collection items.
            action.payload.items = action.payload.items?.filter((item) => {
                const readyForViewing =
                    item?.broadcast?.details?.broadcastStatus === "Ended" &&
                    item?.broadcast?.videos?.[0]?.details?.readyToStream;
                return readyForViewing;
            });

            state.collectionVideosMap[key].totalPages =
                action.payload.totalPages;
            state.collectionVideosMap[key].totalRecords =
                action.payload.totalRecords;
            state.collectionVideosMap[key].page = action.payload.page;
            state.collectionVideosMap[key].pageSize = action.payload.pageSize;

            state.collectionVideosMap[key].items = [
                ...(state.collectionVideosMap[key].items?.filter(
                    (item) => !!item?.details?.id
                ) || []),
                ...(action.payload.items || [])
            ];

            state.hasLoadedCollectionVideos = true;
        });

        builder.addCase(getNextEvents.fulfilled, (state, action) => {
            const key = action.payload.collectionId!;
            state.collectionVideosMap[key] ||=
                {} as CollectionVideosPagedResponse;

            const targetCollection = state.collections.collections?.find(
                (collection) => {
                    return collection?.details?.id === key;
                }
            );

            const isNextEvent =
                targetCollection?.details?.idleState === "NextEvent";

            action.payload.items = action.payload.items?.filter((item) => {
                return (
                    item.broadcast?.details?.broadcastStatus === "Active" ||
                    (isNextEvent &&
                        item.broadcast?.details?.broadcastStatus === "Ready")
                );
            });

            if (action.payload.items?.[0]) {
                state.collectionVideosMap[key].items = [
                    action.payload.items?.[0],
                    ...(state.collectionVideosMap[key].items || [])
                ];
            }
        });

        builder.addCase(getCollectionVideos.rejected, (state, action) => {
            const key = action.meta.arg.collectionId!;

            state.collectionVideosMap[key] ||=
                {} as CollectionVideosPagedResponse;

            state.collectionVideosMap[key].items = [
                ...(state.collectionVideosMap[key].items?.filter(
                    (item) => !!item?.details?.id
                ) || [])
            ];

            state.hasLoadedCollectionVideos = true;
        });
    }
});

export const {
    setConfiguredCatalogId,
    setConfiguredCollectionId,
    setConfiguredBroadcastId,
    setCatalogDetails,
    setCollectionDetails,
    clearCatalog,
    setIsGatedContentDisabled
} = catalogState.actions;

export default catalogState.reducer as Reducer<CatalogState>;
