import styles from "./index.module.scss";
import { GenericModal } from "../GenericModal";
import { useTranslation } from "react-i18next";
import { Modals } from "store/Modals/types";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { IFrameType } from "store/VideoSession/types";
import { useHandleRestore } from "hooks/useHandleRestore";
import LoadingAnimation from "components/loading/LoadingAnimation";
import { SuccessIcon } from "components/Icons/SuccessIcon";

export const PurchaseSuccessModal = ({
    activeModal,
    customerEmail
}: {
    activeModal: Modals;
    customerEmail: string;
}) => {
    const { t } = useTranslation();
    let { isLoading } = useSelector((s: RootState) => s.loading);

    const { iframeType, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );

    const handleRestore = useHandleRestore();

    const restoreAccess = () => {
        handleRestore(customerEmail);
    };

    return (
        <GenericModal
            title={t("modals:restore-title")}
            successButton={t("buttons:resend")}
            successButtonDisabled={isLoading}
            onSuccess={restoreAccess}
            preventCloseOnSuccess
            onClose={() => {
                iframeType === IFrameType.AuxModal &&
                    parentFrame?.closePurchaseModal();
            }}
        >
            <div
                className={`${styles["success-modal-content"]} ${
                    isLoading ? styles.isLoading : ""
                }`}
            >
                {isLoading && <LoadingAnimation />}
                <SuccessIcon />
                {activeModal === Modals.PurchaseRestored ? (
                    <>
                        <h3>{t("modals:link-sent")}</h3>
                        <p>{t("modals:restore-description-success")}</p>
                    </>
                ) : (
                    <>
                        <h3>
                            {t("modals:purchase-found-and-restored:header")}
                        </h3>
                        <p>
                            {t(
                                "modals:purchase-found-and-restored:description-one"
                            )}
                        </p>
                        <p>
                            {t(
                                "modals:purchase-found-and-restored:description-two",
                                { customerEmail }
                            )}
                        </p>
                        <p>
                            {t(
                                "modals:purchase-found-and-restored:description-three"
                            )}
                        </p>
                    </>
                )}
            </div>
        </GenericModal>
    );
};
